// chrome started adding padding to buttons, remove it before bulma sets it up
button {
    padding: 0;
}

@import "~bulmaswatch/darkly/variables";
$body-size:12px;
$bulmaswatch-import-font:false !default;
@import "~bulma/bulma.sass";
@import "~bulmaswatch/darkly/overrides";

.message {
    $font-size: 1rem;
}

// override darkly is-6
.title.is-6 {
    font-size: .85em;
}

.subtitle.is-6 {
    font-size: .85em;
}

// FontAwesome
$fa_font_path: "~@fortawesome/fontawesome-free/webfonts";
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';

// additional bulma changes/fixes
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #2b2b2b;
}

.navbar-item img { max-height: 5rem; }

.columns.is-gapless {
    margin-left: 0;
    margin-right: 0;
    margin-top: 2rem;
    }

.has-text-info {
    color: #5cc1df !important;
    font-weight: bold;
}

.subtitle {
    color: #ffffff !important;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.25;
    }

.filters-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #2b2b2b;
    padding: 0.8rem;
    overflow-y: scroll;
    border-right: 2px solid #2b2b2b;
}

.filters-icon-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #2b2b2b;
    padding: 0.4rem;
    overflow-y: scroll;
    border-right: 2px solid #2b2b2b;
}

.button.is-primary[disabled], fieldset[disabled] .button.is-primary {
    background-color: #5cc1df;
    border-color: transparent;
    box-shadow: none;
    }

.button.is-success.is-hovered, .button.is-success:hover {
    background-color: #5cc1df;
    }
.button.is-success {
    background-color: #111216;
    border-color: transparent;
    color: #fff;
}

.dropdown.is-full-width {
    width: 100%;
}

.tabs.is-medium {
    min-height: 3rem;
    margin-bottom: 0.75rem;
}

.level {
    &.is-scrollable {
        overflow-y: scroll;
        min-height: 5.3rem;
    }
}

.modal {
    position: fixed !important;
    overflow: auto !important;

    .modal-background {
        position: fixed !important; // so page stays dark as we scroll
    }

    .modal-content,
    .modal-card,
    .modal-card-body {
        overflow: visible !important;
    }
}

:root {
    --Facility-colour: #920692;
    --Module-colour: rgb(173, 235, 80);
    --Area-colour: #d3d305;
    --Location-colour: #008000;
    --ProductionUnit-colour: rgb(0, 140, 255);
    --System-colour: #e30059;
    --Subsystem-colour: #ce8fa8;
    --Tag-colour: #87CEEB;
    --Document-colour: #61B680;
    --Activity-colour: #EB6209;
    --Discipline-colour: #ffffff;

    --Construction-colour: #A4C3D2;
    --MechanicalCompletion-colour: #E6E6CA;
    --PreCommissioning-colour: #E6B9A1;
    --Unknown-colour: #8DD4CD;
    --Commission-colour: #FFFFFF;
    --FunctionTest-colour: #FCEEC5;
    --Handover-colour: #AFD5AA;
}


body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    -ms-overflow-style: none;
    overflow-y: hidden;
}

html {
    height: 100%;
    width: 100%;
    background-color: #111216;
    /*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23222' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");*/
    font-family: $family-primary;
}

* {
    scrollbar-width: none;
}

#app-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

summary a * {
    pointer-events: none;
}

::-webkit-scrollbar {
    display: none;
}

svg {
    fill: white;
}

.is-not-allowed {
    cursor: not-allowed;
}

.fa-tags { display:none; }

.fa-tag { display:none; }

.field { border-bottom: 1px solid #5e6d6f; }


.field:hover,
.field.highlighted {
    background-color: #757474;
}

.key-value { border-bottom: 1px solid #5e6d6f; }

.new-menu-column summary{ 
    list-style-type: none; 
}

.new-menu-column summary::marker{ 
    color:#2b2b2b;
    z-index:-5; 
}

.new-menu-popout {
    /*position: absolute;
    top: 140px;
    left: 100px;
    background-color: #4B4F5B;*/
    width: 60px;
    z-index: 10;
}

.new-menu-popoutA {
    position: absolute;
    top: 90px;
    left: 60px;
    /*background-color: #9B9FAB;*/
    background-color: #4B4F5B;
    width: 100px;
    z-index: 1;
}

.new-menu-popoutB {
    /*position: absolute;
    top: 190px;
    left: 140px;
    background-color: #40444F;*/
    width: 60px;
    z-index: 20;
}

.new-menu-popout-item {
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 2rem;
}

.new-menu-popout-item p{
    border-bottom: 1px solid #ffffff;
    font-size: 0.8rem;
    font-weight:bold;
}





@media screen and (min-width: 769px), print {
    .column.is-1, .column.is-1-tablet {
        flex: none;
        width: 60px;
        text-align: center;
        padding-top:10px;
    }
}

@media screen and (max-width: 768px), print {
    .column.is-1, .column.is-1-tablet {
        display: none;
    }
}