.entity-selector {
    .dropdown-content {
        max-height: 13rem;
        overflow: auto;
    }

    .tags-input {
        align-content: flex-start;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        min-height: 2.5em;
        padding: 0;
        position: relative;
        background-color: #fff;
        border-color: #dbdbdb;
        border-radius: 4px;
        color: #363636;
        -webkit-appearance: none;
        align-items: center;
        border: 1px solid transparent;
        box-shadow: none;
        font-size: 1rem;
        justify-content: flex-start;
        line-height: 1.5;
        vertical-align: top;
        width: 100%;

        .input {
            border-color: transparent;
            box-shadow: none;
            display: inline-block;
            flex: 1 0 auto;
            height: 2.3rem;
            margin: .1rem;
            width: auto;
        }

        .tag {
            font-size: 1rem;
            margin: .1rem;
        }
    }
}