.header-container {
    .field:not(:last-child) {
        margin-bottom: unset;
    }

    .project-info {
        height: 4rem;

        .project-name {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 0.75rem;
            color: white;
            font-size: 1.2rem;
        }

        .project-thumbnail {
            border-radius: 50%;
            height: 2.5rem;
            max-height: 2.5rem;
            width: 2.5rem;
            max-width: 2.5rem;
        }
    }

   /* .epm-logo {
        height: 2.5rem;
        width: 2.5rem;
    }*/

    .epm-logo {
        height: 5rem;
        width: 10.6rem;
    }

    .navbar-item {
        justify-content: center;

        .search-bar {
            min-width: 50%;
        }
    }
}