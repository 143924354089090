@use "sass:math";
.project-home {
    height: 100%;
    padding: 2rem;

    .wall-icon {

        height: 6rem;
        width: 6rem;
        padding: 0.5rem;

        &.document {
            stroke: #fff;
            stroke-width: 2;
        }

    }

    $header-height: 4rem;
    $section-height: 42rem;
    $footer-height: 4rem;
    $border-radius: .5rem;
    $default-spacing: 2rem;

    $gray-dark: #121519;
    $gray: #a0a0a0;
    $gray-light: #F3F3F3;

    $lightblue: #66AEE5;

    &.wall {
        grid-column: 1 / -1;
        padding: $default-spacing;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        perspective: 1px;

        scroll-snap-type: block proximity;
        scroll-snap-destination: 0% 100%;
        scroll-snap-points-y: repeat(100%);

        @media screen and (min-width:1024px) {
            grid-column: 2 / -1;
        }
    }


    article {
        min-height: 50vh;
        /*padding-top: math.div($section-height, 1.3);*/
        padding-top:20rem;
        /*margin-bottom: math.div($section-height, 2);*/
        position: relative;
        transform-style: preserve-3d;
        scroll-snap-align: start;

        header {
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            height: 500px;
            padding: $default-spacing;
            border-radius: $border-radius;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            text-shadow: 0 0 20px rgba(0, 0, 0, 0.25);

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
            }

        }

        h1 {
            font-size: 3.5rem;
            font-weight: 200;
            margin-bottom: .5rem;
            margin-top: 3rem;
            line-height: 1;
        }

        h2 {
            font-size: 2.5rem;
            font-weight: 200;
            margin-bottom: .5rem;
            margin-top: 2.5rem;
            line-height: 1;
        }

        p {
            font-size: 1.3rem;
        }

        .progress-percent {
            font-size: 1.2rem;
        }

        .controls {
            display: flex;
            align-items: center;
            position: absolute;
            padding: 0 $default-spacing;
            top: 0;
            left: 0;
            right: 0;
            transform: translateY(-50%);
        }

        .section__content {
            position: relative;
            z-index: 1;
            padding: 0 math.div($default-spacing, 2);
        }

        @media screen and (min-width: 1024px) {
            margin-bottom: $section-height;

            header {
                transform: translateZ(-1px) scale(1.9);
            }

            .section__content {
                padding: 0;
                transform: translateZ(0);
            }
        }
    }

    .boxgrid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
        grid-auto-rows: minmax(11rem, auto);
        grid-gap: 2rem .5rem;

        &__item {
            display: flex;

            &--push {
                @media screen and (min-width: 1280px) {
                    grid-column: span 2;
                    padding-left: 50%;
                }
            }
        }
    }

    .box-item {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: relative;
        border-radius: $border-radius;
        font-size: .875rem;
        color: #FFF;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
        transform: scale(1);
        transition: all .25s ease;
        filter: brightness(100%);
        padding-left: .5rem;
        padding-right: .5rem;
        text-align: center;

        &:hover,
        &:focus {
            transform: scale(1.07);
            filter: brightness(110%);
            z-index: 10;
        }

        &.teal {
            background-color: #89CDCE;
        }

        &.lightBlue {
            background-color: $lightblue;
        }

        &.indigo {
            background-color: #6C73DC;
        }

        &.pink {
            background-color: #EE989A;
        }

        &.yellow {
            background-color: #EAD76E;
        }

        &.orange {
            background-color: #FFD19B;
        }

        &.mint {
            background-color: #90B9CC;
        }

        &.grey {
            background-color: #F4FAFB;
        }

        &--image {
            padding: 0;
        }

        &__img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            object-position: center;
            border-radius: $border-radius;
        }
    }

    .user {
        margin-left: auto;
        text-align: center;
        padding-top: 2rem;

        &-name {
            display: block;
            font-size: 1.5rem;
            font-weight: 200;
        }

        img {
            width: 8.3rem;
            height: 8.3rem;
            border-radius: 50%;
            margin-bottom: .5rem;
            box-shadow: 0 0 38px rgba(0, 0, 0, 0.30);
        }

        i {
            width: 8.3rem;
            height: 8.3rem;
            border-radius: 50%;
            margin-bottom: .5rem;
        }
    }

    .btn {
        display: flex;
        flex: 0 0 50px;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border: 0;
        padding: 0;
        border: 1px solid transparent;
        border-radius: 50%;
        background-color: #FFF;
        margin: 0 .5rem;
    }

    button:focus {
        outline: 0;
        border-color: $lightblue;
        box-shadow: 0 0 0 1px $lightblue;
    }
}