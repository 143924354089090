.admin-container {
    height: 96%;
    display: flex;
    padding-top: 0.5rem;
    padding-left: 0.2rem;
    justify-content: flex-start;

    .admin-content {
        width: 100%;
        height: 100%;
        overflow-x: scroll;
        .admin{
            height: 100%;
            display: flex;
            flex-direction: column;
            .main-content{
                flex-grow: 1;
                overflow-y: scroll;
            }
        }
    }

    .menu {
        height: 100%;
        overflow-y: scroll;
    }

    .tabs ul {
        flex-shrink: 1;
        flex-wrap: wrap;
    }

    .select-search {
        color: black;
    }
}