.search-result {
    padding: 0.65rem;
    width: 100%;
    border-bottom: 1px solid #5e6d6f;
    //min-height: 8rem;
    //min-height: max-content;
    height: max-content;

    .is-loading{
        height: 7rem;
    }

    cursor: pointer;

    &:hover,
    &.highlighted {
        background-color: #51565a
    }

    .media {
        height: 100%;

        .media-right {
            text-align: center;
        }

        .media-left {
            display: flex;
            /*display:none;*/
            flex-direction: column;
            place-items: center;
            place-content: space-between;
            height: 100%;

            .vertical-line {
                width: 1px;
                height: 50%;

                &.Facility {
                    background: var(--Facility-colour);
                }

                &.Module {
                    background: var(--Module-colour);
                }

                &.Area {
                    background: var(--Area-colour);
                }

                &.Location {
                    background: var(--Location-colour);
                }

                &.ProductionUnit {
                    background: var(--ProductionUnit-colour);
                }

                &.System {
                    background: var(--System-colour);
                }

                &.Subsystem {
                    background: var(--Subsystem-colour);
                }

                &.Tag {
                    background: var(--Tag-colour);
                }

                &.Document {
                    background: var(--Document-colour);
                }

                &.Activity {
                    background: var(--Activity-colour);
                }

                &.Discipline {
                    background: var(--Discipline-colour);
                }
            }
        }

        .media-content {
            overflow-x: unset;
        }

        &.Activity {
            padding-left: 1rem;
            border-left: 0.3rem solid var(--Unknown-colour);

            .content p {
                margin-bottom: 0;
            }

            &.maturity-Construction {
                border-left-color: var(--Construction-colour);
            }

            &.maturity-MechanicalCompletion {
                border-left-color: var(--MechanicalCompletion-colour);
            }

            &.maturity-PreCommissioning {
                border-left-color: var(--PreCommissioning-colour);
            }

            &.maturity-PreCommissioning {
                border-left-color: var(--PreCommissioning-colour);
            }

            &.maturity-Commission {
                border-left-color: var(--Commission-colour);
            }

            &.maturity-FunctionTest {
                border-left-color: var(--FunctionTest-colour);
            }

            &.maturity-Handover {
                border-left-color: var(--Handover-colour);
            }
        }
    }
}

.detailed {
    .search-result {
        //min-height:6.5rem;
        height: max-content;
    }
}