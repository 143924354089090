@import "~bulmaswatch/darkly/variables";

.info-panel {
    height: 100%;
    display: flex;
    background: rgb(53, 55, 58);
    overflow-y: scroll;
    color: white;

    .info-panel-content {

        width: 100%;

        .title {
            color: white;
            font-size: 1rem;
        }

        .subtitle {
            color: #5cc1df;
            font-size: 0.75em;
        }

        .entity-icon {
            width: 3rem;
            height: 3rem;
        }

        .activity {
            display: flex;
            justify-content: left;
            text-align: left;
            align-items: center;
            width: 100%;

            .activity-header {
                display: flex;
                justify-content: flex-end;
                text-align: right;
                align-items: center;
                width: 50%;

                .detail {
                    margin-right: 0.25rem;
                }
            }

            .target-header {
                display: flex;
                justify-content: flex-start;
                text-align: left;
                align-items: center;
                width: 50%;

                .detail {
                    margin-left: 0.25rem;
                }
            }
        }

        .about {
            padding: 0.75rem;
            position: sticky;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: rgb(53, 55, 58);
            width: 100%;
            top: 0;

            .entity-header {
                display: flex;
                width: 100%;

                &.with-image {
                    flex-direction: column;

                    .title {
                        font-size: 2rem;
                        margin-left: 1rem;
                    }
                }

                .detail {
                    margin-left: 1rem;
                }
            }

            .media{
                flex-grow: 1;
            }

            .Activity {
                padding-left: 1rem;
                border-left: 0.3rem solid var(--Unknown-colour);

                .content p {
                    margin-bottom: 0;
                }

                &.maturity-Construction {
                    border-left-color: var(--Construction-colour);
                }

                &.maturity-MechanicalCompletion {
                    border-left-color: var(--MechanicalCompletion-colour);
                }

                &.maturity-PreCommissioning {
                    border-left-color: var(--PreCommissioning-colour);
                }

                &.maturity-PreCommissioning {
                    border-left-color: var(--PreCommissioning-colour);
                }

                &.maturity-Commission {
                    border-left-color: var(--Commission-colour);
                }

                &.maturity-FunctionTest {
                    border-left-color: var(--FunctionTest-colour);
                }

                &.maturity-Handover {
                    border-left-color: var(--Handover-colour);
                }
            }
        }

        .properties {
            details {
                padding: 1rem;
                border-bottom: 1px solid #5e6d6f;

                &[open] {
                    summary {
                        padding-bottom: 0.5rem;
                        margin-bottom: 0.5rem;
                        border-bottom: 1px solid #5e6d6f;
                    }
                }

                summary {
                    cursor: pointer;
                    /*background-color: $primary;*/
                    background-color: #1abc9c;
                    outline: none;
                    margin: -1rem;
                    padding: 1rem;
                    display: flex;
                }
            }

            .summary {
                /*background-color: $primary;*/
                background-color: #1abc9c;
                outline: none;
                padding: 1rem;
                display: flex;
            }

            .document-viewer {
                padding: 1rem;
                margin-bottom: 0.5rem;
                border-bottom: 1px solid #5e6d6f;

                embed {
                    height: 30rem;
                    width: 100%;
                }

                .cannot-display {
                    display: flex;
                    justify-content: center;
                }
            }

            .summary,
            summary {

                .title,
                .subtitle {
                    margin-left: 1rem;
                }

                .summary-contents {
                    width: 100%;
                }

                .summary-title {
                    display: flex;
                    width: 100%;
                }

                .summary-pair {
                    display: flex;
                    align-content: space-between;
                    justify-content: space-between;
                    width: 100%;
                }
            }

            .key-value {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                margin-bottom: 0.25rem;

                &.overridden {
                    text-decoration: line-through
                }

                .key {
                    color: #ffffff;
                    width: 50%;

                    svg {
                        margin-right: 0.2rem;
                    }
                }

                .value {
                    font-weight: bold;
                    width: 50%;
                }
            }

            .steps {
                .step-complete-details {
                    color: #5cc1df;
                    align-items: center;

                    .completed-by {
                        font-weight: bold;
                        margin-right: 1rem;
                    }

                    .completed-on {
                        margin-right: 1rem;
                        font-weight: bold;
                    }
                }
                
                .summary {
                    cursor: pointer;
                    /*background-color: $primary;*/
                    background-color: #5cc1df;
                    outline: none;
                    margin: -1rem;
                    padding: 1rem;
                    display: flex;
                }

                .progress {
                    margin-top: 0.75rem;
                }

                .signatory {
                    margin-top: 0.25rem;
                    margin-bottom: 0.25rem;
                    padding: 0;
                    margin-left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    time {
                        font-weight: bold;
                        color: white;
                    }
                }

                .title {
                    font-weight: normal;
                }

                .subtitle {
                    font-weight: normal;
                    font-size: 0.85rem;
                }

                .step-has-answer {
                    td {
                        border-bottom: none;
                    }
                }

                .step-answer {
                    td {
                        border-top-style: dashed;
                        border-top-width: thin;
                    }
                }
            }

            .definition {
                width: 100%;

                .definition-list {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    width: 100%;

                    .entity-count {
                        display: flex;
                        justify-items: center;
                        align-items: center;
                        margin: 1rem;

                        .entity-icon {
                            padding: unset;
                        }

                        .entity-count-details {
                            margin-left: 1rem;
                            text-align: center;
                        }
                    }
                }

                .breakdown-list {
                    li {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }

            .walkthrough {
                width: 100%;

                summary {
                    display: flex;
                    align-items: center;
                    justify-items: center;
                    justify-content: space-between;
                }

                .walkthrough-viewer {
                    width: 100%;
                    height: 500px;
                }
            }

            .dependencies {
                width: 100%;

                summary {
                    display: flex;

                    .title {
                        margin-left: 1rem;
                    }
                }

                .dependency-list {
                    width: 100%;

                    li {
                        margin-top: 0.5rem;
                    }

                    .name {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }

            .display-labels {
                .key {
                    text-transform: uppercase;
                }
            }

            .workscope {
                width: 100%;

                summary {
                    display: flex;

                    .title {
                        margin-left: 1rem;
                    }
                }

                .workscope-list {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    ul {
                        margin-bottom: 0.5rem;

                        li {
                            margin-top: 0.5rem;
                        }
                    }

                    button {
                        font-size: 1rem;
                    }

                    .name {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}