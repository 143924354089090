.progress-dashboard {
    height: 100%;
    overflow-y: scroll;

    .message {
        width: 100%;
        max-width: 378px;

        &:hover,
        &:focus {
            transform: scale(1.05);
            filter: brightness(105%);
            z-index: 10;
            box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px #1abc9c;
        }

        &.active {
            filter: brightness(105%);
            z-index: 10;
            box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px #15ba26;
        }

        .message-body {
            height: 23rem;
        }
    }

}