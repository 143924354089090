.dashboard {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-content: space-between;

    .message {
        font-size: 1rem;

        .message-header {
            font-size: 1.2rem;
            flex-wrap: wrap;
        }
    }

    .level {
        margin-bottom: 0;
    }

    .totals {
        min-height: 4rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        overflow-x: auto;
    }

    .dashboard-options {
        padding: 0.5rem;
        min-height: 4rem;

        .icon {
            margin-right: 0.4rem;
        }
}

.project-stats-total {
    border-top: #282f2f 2px solid;
    border-bottom: #282f2f 2px solid;
}

.project-stats {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .project-stat {
        width: 100%;
        max-width: 50rem;
        display: flex;

        @media (max-width: 745px) {
            flex-direction: column;
            height: 30rem;
        }

        @media (min-width: 746px) {
            flex-direction: row;
            height: 15rem;
        }

        .data {
            @media (max-width: 745px) {
                padding-bottom: 0.5rem;
                border-bottom: 1px solid #5e6d6f;
                width: 100%;
            }

            @media (min-width: 746px) {
                width: 50%;
                padding-right: 0.5rem;
                border-right: 1px solid #5e6d6f;
            }
        }

        .missing-data {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 745px) {
                width: 100%;
                height: 50%;
            }

            @media (min-width: 746px) {
                width: 50%;
                height: 100%;
            }

            svg {
                height: 80%;
                width: 80%;
            }

        }

        .chart {
            @media (max-width: 745px) {
                width: 100%;
                height: 50%;

            }

            @media (min-width: 746px) {
                width: 50%;
                height: 100%;

            }

        }

    }
}

.breakdown {

    .progress-info {
        display: flex;
        justify-content: space-between;
    }

    li {
        margin-bottom: 1rem;
    }

    .breakdown-header {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .message-body {
        @media (min-width: 746px) {
            height: 72rem;
            overflow-y: scroll;
        }

        padding-bottom: 1rem;
    }

}

.project-progress {

    .message {

        .progress-chart {
            width: 100%;
            height: 30vh;
            min-height: 22rem;
            overflow-x: hidden;
            display: flex;
            flex-direction: row;

            .chart {
                height: 100%;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: auto;
                overflow-x: hidden;
                overflow-y: hidden;
                z-index: 1;
            }

            .chart-options {
                height: 100%;
                overflow-y: scroll;
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 15rem;
            }

            .missing-data {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    height: 80%;
                }
            }
        }

    }
}
}