@import '~pretty-checkbox/src/pretty-checkbox.scss';

.filter-option {
    color: rgb(235, 235, 235);
    padding-left: 2.5rem;
    display: flex;
    height: 1.75rem;
    align-items: center;
    font-size: 1rem;

    .filter-option-label {
        * {
            margin-left: .5rem;
        }
    }
}


.show-more-text {
    padding-left: 40px;
    padding-top: 5px;
    font-size: 1rem;
    color: rgb(55, 157, 224);
}

.filter-option-search-bar {
    padding-left: 1.25rem;

    input {
        color: white;
        border-radius: 7px;
        background: rgba(255, 255, 255, 0);
        border: 1px solid rgb(170, 170, 170);
        padding: 5px;
        margin-bottom: 10px;
    }
}

.field {
    .label {
        * {
            margin-right: .3rem;
        }
    }
}