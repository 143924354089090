.entity-icon {
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 4rem;
    max-height: 100%;
    border: 2px solid white;

    svg {
        stroke: white;
        fill: white;
        width: 65%;
        height: 65%;
    }

    &.entity-type-Facility {
        border-color: var(--Facility-colour);
    }

    &.entity-type-Module {
        border-color: var(--Module-colour);
    }

    &.entity-type-Area {
        border-color: var(--Area-colour);
    }

    &.entity-type-Location {
        border-color: var(--Location-colour);
    }

    &.entity-type-ProductionUnit {
        border-color: var(--ProductionUnit-colour);
    }

    &.entity-type-System {
        border-color: var(--System-colour);
    }

    &.entity-type-Subsystem {
        border-color: var(--Subsystem-colour);
    }

    &.entity-type-Tag {
        border-color: var(--Tag-colour);
    }

    &.entity-type-Document {
        border-color: var(--Document-colour);
    }

    &.entity-type-Activity {
        border-color: var(--Activity-colour);
    }

    &.entity-type-Discipline {
        border-color: var(--Discipline-colour);
    }
    .tag-status {
        color: #fff;
        animation: rotation 25s infinite linear;

        &.Poor {
            color: red;
        }

        &.Moderate {
            color: rgb(238, 177, 65);
        }

        &.Good {
            color: rgb(67, 153, 67);
        }

        .st0 {
            fill: currentColor;
            stroke: #d0d0d0;
        }

    }

    @keyframes rotation {
        to {
            transform: rotate(0deg);
        }

        from {
            transform: rotate(359deg);
        }
    }

}