@import '~pretty-checkbox/src/pretty-checkbox.scss';
.filters-container {
    display: flex;
    flex-direction: column;
    height:100%;
    background-color: #4B4F5B;
    padding: 0.8rem;
    overflow-y: scroll;
    border-right: 2px solid #4B4F5B; //bulma

    .clear-filter{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-items: center;
    }

    .checkbox {
        padding-left: 30px;
        padding-right: 5px;
        padding-bottom: 5px;
        color: #95949F;
        font-size: 0.8rem;
        font-weight: 400;
        display: flex;
        align-items: center;

        input {
            margin-right: 5px;
        }

    }

    .label {
        padding-left: 20px;
        color: white;
        padding-top: 10px;
        font-size: 1rem;
        font-weight: 400;

    }

    .no-filter{
        color: rgb(116, 116, 116);
    }

    .help {
        padding-left: 40px;
    }
}

.toggle {
    cursor: pointer;
}

.search-results {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;

   /* background-color: rgb(60, 63, 66);*/
   background-color:#000000;

    .search-results-content {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        width: 100%;
        height: 100%;

        &.detailed {
            overflow-x: visible;
            white-space: normal;
        }

        .search-results-header {
            display: flex;
            justify-content: space-between;
            padding: .5rem;
            padding-left: 1.5em;
            align-items: center;
            justify-items: center;
            border-bottom: 1px solid #5e6d6f;

            .header-start {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .description-toggle {
                margin-left: auto;
                padding-left: 1.5rem;
                padding-right: 0.75rem;
                margin-top: 2px;

                .state {
                    input {
                        cursor: pointer;
                    }

                    label {
                        color: '#95949F';
                    }
                }
            }

            .search-result-heading {
                color: #5cc1df !important;
                font-weight: bold;
            }

            .search-result-total {
                padding-left: 0.75rem;
                color: white;
            }

            .filter-panel-toggle {
                color: white;
                padding-right: 0.75rem;
                cursor: pointer;
            }
        }

        .search-results-body {
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            width: 100%;
            align-items: center;
            max-height: 90%;

            .loading-spinner{
                margin-top: 1rem;
            }
        }
    }


}